.card{
    height: 65vh;
    max-height: fit-content;
}
.add-cart-btn{
    background: #2F5FA0;
    color: white;
    cursor: pointer;
    outline: none;
}

.card-title:hover {
    color: #2F5FA0;
    cursor: pointer;
}
.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-top: 20px;
    padding: 0;
    margin-right: 3%;
}

.pagination a {
    cursor: default;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(9, 30, 66, 0.2);
    color: #2F5FA0;
    margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
    background-color: rgb(248, 246, 244);
    cursor: pointer;
}

.pagination li a {
    font-weight: bold;
}

.pagination li.active a {
    color: #fff;
    background: #2F5FA0;
}

.pagination li.disabled a {
    pointer-events: none;
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}

.empty-list{
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: rgb(141, 140, 140);
    font-size: 28px;
    padding: 5px;
}