.edit-add-btn {
     background-color: #2F5FA0;
     color:white;
     border: 1px solid #2F5FA0;
     padding: .5rem;
}
.contract:hover{
     cursor: pointer;
     background-color: bisque;
     height: 3%;
}