.fb-button{
    border-radius: 5px;
    background-color: #3b5998;
    height: 100%;
    color: white;
    text-align: center;
    border: none;
    box-shadow:0px 3px #d4e1f8;
    border: 1px solid lightgray;
}
.google-btn{
    border-radius: 5px !important;
    height: 100% !important;
    color: #3b5998 !important;
    text-align: center !important;
    border: none !important;
    box-shadow:0px 3px lightgray !important;
    border: 1px solid lightgray !important;
}