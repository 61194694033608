@media screen and (max-width: 1024px) {
    .img-carousle {
        min-height: 25vh;
    }
    .banner-container{
        margin: 0;
    }
}
.banner-container{
    margin:5%
}
@media screen and (min-width: 1024px) {
    .img-carousle {
        min-height: 60vh;
    }
}