.collapsed{
    border: none;
}
@media screen and (max-width: 1024px) {
    .collapsed {
        display: flex;
    }
    .nav-titles{
        display: none;
    }
    .navbar-item{
        max-width: 50%;
    }
    .navbar-header{
        position: absolute;
        right: 5%;
    }
    .navbar-header-ar {
        position: absolute;
        left: 5%;
    }
    .navbar-collapse{
        display: flex;
        flex-direction: row;
        text-align: center;
    }
    
}
@media screen and (min-width: 1024px) {
    .collapsed {
        display: none;
    }
    .nav-titles {
        display: flex;
        flex-direction: row;
    }
}
