.inputStickyNav{
    background-color: #264D82;
    height: 100%;
    border: none;
    color: white;
    outline: none;
}
.sticky-nav{
    position: sticky;
    top: .1%;
     background-color: #2F5FA0;
     z-index: 1;
     height: 4rem;
}
.collapsed-sticky {
    border: none;
}

@media screen and (max-width: 1270px) {
    .collapsed-sticky {
        display: flex;
    }
.sticky-nav {
    background-color: #2F5FA0;
    z-index: 1;
    height: 2rem;
}
    .nav-titles-sticky {
        display: none;
    }
.sticky-nav {
    position: relative;
   
}
   

  

}

@media screen and (min-width: 1270px) {
    .collapsed-sticky {
        display: none;
    }
.search-sticky{
    display: none;
}
    .nav-titles-sticky {
        display: flex;
        flex-direction: row;
    }
}
