.item {
    display: block;
    cursor: pointer;
    text-transform: capitalize;
}
.item>.children {
    padding: 0 17px 0 17px;
    color: #2F5FA0;
}
.item>.toggler {
    display: inline-block;
}