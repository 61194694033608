.continue-action {
    background-color: transparent;
    color: rgb(141, 140, 140);
    font-size: 28px;
}
.feedback-btn{
    background-color: #FFFFFF;
    border: 1px solid #2F5FA0;
    padding: .5rem;
    color: #2F5FA0;
}