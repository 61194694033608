body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
@media screen and (max-width: 1024px) {

html,
body {
  overflow-x: hidden !important;
  width: 100vw !important;
}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2F5FA0;
  border-radius: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #2F5FA0 !important;
}

.btnHover:hover{
  cursor: pointer;
}
.btnHoverUnderLine:hover{
  cursor: pointer;
  text-decoration: underline;
}

.btnScale:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.btnClick:hover{
   color: white;
   background-color: #2F5FA0;
}

.btnClickWhite:hover {
  color: #2F5FA0;
  background-color: white;
  border: 1px solid #2F5FA0;
}

p{
  text-align: justify;
}

.hide-pagination {
  display: none;
}

.toastContainer{
  border-radius: 30px !important;
}
.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  color: #2F5FA0;
  font-size: 0.875rem !important;
}

.toastProgress {
  background: #2F5FA0 !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: transparent !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}