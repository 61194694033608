.remove-btn{
    background-color: white;
    color: #2F5FA0;
    border: 1px solid #2F5FA0;
    border-radius: 5px;
}
.add-to-cart-btn{
    background-color: #2F5FA0;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
}
.quantity-input{
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    padding: 5px;
    text-align: center;
}
.continue-action {
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: rgb(141, 140, 140);
    font-size: 28px;
    padding: 10px;
    transition: all 0.5s;
    cursor: pointer;
    flex-direction: column;
    display: flex;
}

.continue-action span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.continue-action span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.continue-action:hover span {
    padding-right: 25px;
}

.continue-action:hover span:after {
    opacity: 1;
    right: 0;
}
