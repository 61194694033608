.step-img{
    background-color: #2F5FA0;
    border: 1px solid black;
    border-radius: 30px;
    width: 3rem;
    height: 3rem;
    padding-top: .5rem;
}
.empty-action {
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: rgb(141, 140, 140);
    font-size: 28px;
    padding: 10px;
    transition: all 0.5s;
}
@media screen and (max-width: 1024px) {
    .step,.line{
        max-width: 17vw !important;
        margin: 0 !important;
        padding: 0 !important;
    }
  
}
.delete-add-btn{
     background-color: #FFFFFF;
     border: 1px solid #2F5FA0;
     padding: .5rem;
     color: #2F5FA0;
}
.edit-add-btn {
     background-color: #2F5FA0;
     color:white;
     border: 1px solid #2F5FA0;
     padding: .5rem;
}