.main{
    background-color: #2F5FA0;
    color: white;
}
.nameHeader{
     text-transform: uppercase;
     font-size: 24;
     font-weight : bold;
}
.inputFooter{
      background-color: #264D82;
      border: none;
      color: white;
      outline: none;
}
.redirectLinks{
    font-size: 12;
    cursor: pointer;
}